<template>
  <div class="sign-form">
    <el-form ref="refForm" label-width="120px" label-position="right" :model="dataForm" :rules="rules"
             :inline="false"
             size="small">
      <el-card class="sign-card">
        <!--        <el-form-item label="签约信息ID" v-if="dataForm.id">-->
        <!--          <span>{{ dataForm.id }}</span>-->
        <!--        </el-form-item>-->
        <el-row>
          <el-col :span="8" v-if="!dataForm.id">
            <el-form-item label="是否二次签约" prop="is_second_sign">
              <el-radio-group v-model="dataForm.is_second_sign">
                <el-radio label="0">首次签约</el-radio>
                <el-radio label="1">二次签约</el-radio>
              </el-radio-group>
            </el-form-item>

          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="" :offset="3" v-if="dataForm.is_second_sign==1&&!dataForm.artist_id" prop="sign_artist_id">
            <artist-search-custom :type="``" @handleSelect="selectArtist" :show-first-group="false"
                                  style="width: 100%"/>
          </el-form-item>
        </el-row>


        <el-form-item label="红人昵称" prop="nickname">
          <el-input v-model="dataForm.nickname" placeholder="输入 红人昵称" maxlength="100" :clearable="true"
                    @blur="checkNickname"
                    show-word-limit/>
        </el-form-item>
        <el-row>
          <el-col :span="8">
            <el-form-item label="签约日期" prop="sign_date">
              <el-date-picker
                v-model="dataForm.sign_date"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择签约日期" :clearable="true" style="width: 100%" @change="changeSignDate">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签约年份" prop="year">
              <el-date-picker
                v-model="dataForm.year"
                type="year"
                format="yyyy"
                value-format="yyyy"
                placeholder="选择签约年份" style="width: 100%" :clearable="true">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="签约月份" prop="month">
              <el-select v-model="dataForm.month" placeholder="选择 签约月份" style="width: 100%"
                         :clearable="true">
                <el-option
                  v-for="item in monthOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="签约前平台">
          <BefPlatformInfo :data="dataForm.sign_bef_info" :edit="true" @currentInfo="getBefPlatformInfo"/>
        </el-form-item>
      </el-card>
      <el-card class="sign-card">
        <el-row>
          <el-col :span="8">
            <el-form-item label="签约人" required>
              <SignatoryQuery v-model="dataForm.signatory" :name="dataForm.signatory"
                              :showSet="true" @handleSelect="handleSelect"
                              @changeValue="changeSignatory"/>
            </el-form-item>

          </el-col>
          <el-col :span="8">
            <el-form-item label="红人姓名">
              <el-input v-model="dataForm.real_name" placeholder="输入红人的真实姓名" :clearable="true"
                        maxlength="50"
                        show-word-limit/>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="身份证号" prop="id_card">
              <el-input v-model="dataForm.id_card" placeholder="输入红人的身份证号" :clearable="true"
                        maxlength="18"
                        show-word-limit/>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="签约后主平台" prop="platform_code_aft">
              <el-select v-model="dataForm.platform_code_aft" placeholder="选择 签约后平台" :clearable="true">
                <el-option
                  v-for="item in platformOptions"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合约性质">
              <el-select v-model="dataForm.nature" placeholder="选择 合约性质" :clearable="true">
                <el-option :label="`全约`" :value="1">全约</el-option>
                <el-option :label="`商务约`" :value="2">商务约</el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合约年限" prop="term">
              <el-input v-model="dataForm.term" placeholder="输入 合约年限" :clearable="true"
                        oninput="value=value.replace(/[^\d^\.^\-]/g,'')">
                <template slot="append">年</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="续约年限">
              <el-input v-model="dataForm.renewal_years" placeholder="续约年限" :clearable="true"
                        oninput="value=value.replace(/[^\d^\.^\-]/g,'')">
                <template slot="append">年</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="续约条件(万)" prop="renewal_cond">
              <el-input v-model="dataForm.renewal_cond" placeholder="续约条件（万）" :clearable="true"
                        oninput="value=value.replace(/[^\d^\.^\-]/g,'')">
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="博主分成比例">
              <el-input v-model="dataForm.blogger_ratio" placeholder="博主分成比例" :clearable="true"
                        oninput="value=value.replace(/[^\d^\.^\-]/g,'')">
                <template slot="append">%</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="账号来源">
              <el-select v-model="dataForm.account_source" placeholder="账号来源" :clearable="true">
                <el-option :label="item.label" :value="item.val"
                           v-for="(item,idx) in accountSourceOptions" :key="idx">
                  {{ item.label }}
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="!dataForm.id">
            <el-form-item label="分配组别" prop="group_id">
              <EcpDeptSelect ref='refDeptCascader' @handleSelect='selectDept' />

              <!--                                                      <DeptCascader ref="refDeptCascader" @change="selectDept"/>-->
            </el-form-item>
          </el-col>
          <el-col :span="16" v-else>
            <span style="line-height:32px;margin-left: 10px;font-size: 10px;color: #909399"><i
              class="el-icon-info"></i>
              “分配组别修改”只能通过“转组功能”修改,即编辑操作“保存不生效”
            </span>
          </el-col>
        </el-row>

        <el-form-item label="备注">
          <el-input type="textarea" v-model="dataForm.remark" placeholder="输入 备注" maxlength="255"
                    show-word-limit
                    :clearable="true"/>
        </el-form-item>
      </el-card>
    </el-form>

    <el-row>
      <el-col :span="8" style="text-align: center;width: 100%">
        <el-button type="primary" @click="saveConfirm" v-if="userPermissions.indexOf('sign_enter_page')">
          <i class="el-icon-circle-check"></i> {{ dataForm.id ? '保存' : '录入' }} - 签约信息
        </el-button>
      </el-col>
    </el-row>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BefPlatformInfo from '@/pages/sign_info/components/BefPlatformInfo'
import SignatoryQuery from '@/pages/sign_info/components/SignatoryQuery'
import ArtistSearchCustom from '@/components/artist/ArtistSearchCustom'
import EcpDeptSelect from '@/pages/commerce/kol_mapper/EcpDeptSelect'

export default {
  name: 'SignInfoCreateEdit',
  components: {
    BefPlatformInfo, SignatoryQuery, ArtistSearchCustom, EcpDeptSelect
  },
  watch: {
    // eslint-disable-next-line
    '$route'(to, from) {
      this.initForm()
    }
  },
  data() {
    //验证红人id
    let validateSignid = (rule, value, callback) => {
      if (value === '' || this.dataForm.sign_artist_id == null) {
        callback(new Error(''))
      } else {
        if (this.dataForm.sign_artist_id !== '') {
          // console.log(this.dataForm.sign_artist_id)
          callback()
          // this.$refs.dataForm.validateField('sign_artist_id')
        }
        callback()
      }
    }

    return {
      id: null,
      dataForm: {
        year: null,
        month: null,
        sign_date: null,
        group_id: null,
        account_source: null,
        nickname: '',
        is_second_sign: '0',
        sign_artist_id: null
      },
      accountSourceOptions: [
        { label: '组内提供微信', val: 'pwg' },
        { label: '自己寻找', val: 'fys' }
      ],
      monthOptions: [],
      signatoryOptions: [],
      platformOptions: [],
      rules: {
        nickname: [{ required: true, message: '输入红人昵称', trigger: 'blur' }],
        signatory: [{ required: true, message: '输入签约人', trigger: 'blur' }],
        sign_date: [{ required: true, message: '输入签约时间', trigger: 'blur' }],
        id_card: [{ required: true, message: '输入身份证号', trigger: 'blur' }],
        group_id: [{ required: true, message: '分组必选', trigger: 'blur' }],
        term: [{ required: true, message: '输入合约年限', trigger: 'blur' }],
        platform_code_aft: [{ required: true, message: '输入签约后主平台', trigger: 'blur' }],
        renewal_cond: [{ required: true, message: '续约条件必填', trigger: 'blur' }],
        is_second_sign: [{ required: true, message: '是否二次签约必选', trigger: 'blur' }],
        sign_artist_id: [{ validator: validateSignid, message: '首次签约id必选', trigger: 'blur' }]

      },
      searchCondition: {
        group_id: null,
        artist_id: null,
        nickname: null,
        ym: null,
        platform_code: null,
        platform_name: ''
      }
    }
  },

  created() {
    this.initOptions()
  },
  mounted() {
    this.initForm()
  },
  computed: {
    ...mapGetters(['userPermissions'])
  },
  methods: {
    //验证是否有红人id
    async initSignatoryOption() {
      let { list } = await this.$api.getSignInfoSignatoryList()
      this.signatoryOptions = []
      list.forEach((item) => {
        this.signatoryOptions.push({ value: item.userid, label: item.name })
      })
    },
    async checkNickname() {
      if (this.dataForm.is_second_sign == '1') {
        return false
      }
      let params = { id: this.dataForm.artist_id, nickname: this.dataForm.nickname }
      let { is_used, artist_id } = await this.$api.isUsedArtistNickname(params)
      if (is_used) {
        let title = '红人昵称：【' + params.nickname + '】已被占用'
        // this.$message.warning('红人昵称：【' + params.nickname + '】已被占用')
        this.$notify({
          title: title,
          dangerouslyUseHTMLString: true,
          message: '<a href="/artist/' + artist_id + '/edit">查看：' + params.nickname + '</a>',
          iconClass: 'el-icon-info',
          offset: 100,
          type: 'warning'
        })
      }
    },
    //初始化月份下拉框
    initOptions() {
      this.monthOptions = []
      for (let i = 1; i <= 12; i++) {
        this.monthOptions.push({ value: i, label: i + '月份' })
      }
      this.getPlatforms()
      this.initSignatoryOption()
    },
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getPlatforms()
      this.platformOptions = list
    },
    changeSignDate() {
      let sign_date = this.dataForm.sign_date
      if (sign_date.indexOf('-') > -1) {
        let arr = sign_date.split('-')
        this.dataForm.year = arr[0]
        this.dataForm.month = parseInt(arr[1])
      }

    },
    // 确认保存按钮
    async saveConfirm() {
      // 调用组件的数据验证方法
      this.$refs['refForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
      return true
    },
    // 相关信息的保存处理
    async formSubmit() {
      if (this.dataForm.is_second_sign) {
        this.dataForm.is_second_sign = this.dataForm.is_second_sign * 1
      }

      let id = await this.$api.saveSignInfo(this.dataForm)
      if (id) {
        this.$notify.success('保存成功！')
        this.detailPage(id)
        // this.initForm()
      }
      // if (!this.artistId && id) {
      //   // this.$router.push(this.$route.matched[1].path+'/'+id+'/edit')
      //   this.$router.push(this.$route.matched[1].path)
      // }
    },
    // 选择分组
    selectGroup(val) {
      this.dataForm.group_id = val
    },
    selectDept(val) {

      let group_id = null
      if (val && val.length > 1) {
        group_id = val[val.length - 1]
      }
      this.dataForm.group_id = group_id.dpt_id
    },
    initForm() {
      //
      this.id = this.$route.params.sign_id
      if (this.id) {
        this.getDetail()
      } else {
        this.dataForm = {
          year: null, month: null, sign_date: null, group_id: null,
          nickname: '',
          is_second_sign: '0',
          sign_artist_id: null
        }
      }
    },
    async getDetail() {
      let { info } = await this.$api.getSignInfo(this.id)
      this.dataForm = info
    },
    // 响应编辑按钮
    async editPage(id) {
      this.$router.push('/sign/' + id + '/edit')
    },
    //获取
    getBefPlatformInfo(val) {
      this.dataForm.sign_bef_info = [...val]
      // console.log(this.dataForm.sign_bef_info)
    },
    async detailPage(id) {
      this.$router.push('/sign/' + id + '/detail')
    },
    //切换签约人
    handleSelect(item) {
      this.dataForm.signatory = item.value
    },
    async changeSignatory(val) {
      this.dataForm.signatory = val
    },
    async selectArtist(selectInfo) {
      if(!this.dataForm.artist_id){
        this.dataForm.sign_artist_id = selectInfo.artist_id
        this.dataForm.nickname = selectInfo.nickname||''
      }

    }
  },
  destroyed() {
    this.dataForm = {
      year: null, month: null, sign_date: null, group_id: null, is_second_sign: '0',
      sign_artist_id: null
    }
  }

}
</script>

<style scoped>
.sign-form {
  padding-top: 20px;
  width: 1000px;
  min-width: 900px;
  margin: auto;
}

.sign-card {
  margin-bottom: 20px;
}
</style>
